import React, {useEffect, useRef, useState} from 'react';
import {calculateRouteWithPrayerStops} from '../utils/routeCalculator';
import DirectionsList from "./DirectionsList";

function RouteForm({
                       setDirections,
                       setRouteSteps,
                       setError,
                       masjids,
                       setZoomToDestination,
                       destinationAddress,
                       setDestinationAddress,
                       setShowDirections,
                       showDirections,
                       routeSteps
                   }) {
    const [startAddress, setStartAddress] = useState('');
    const [departureTime, setDepartureTime] = useState(
        new Date().toISOString().slice(0, 16)
    );
    const [timeOption, setTimeOption] = useState('leave_now');

    const destinationInputRef = useRef(null);
    const startInputRef = useRef(null);

    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            const destinationAutocomplete = new window.google.maps.places.Autocomplete(
                destinationInputRef.current
            );
            destinationAutocomplete.addListener('place_changed', () => {
                const place = destinationAutocomplete.getPlace();
                setDestinationAddress(place.formatted_address || place.name);
            });

            if (startInputRef.current) {
                const startAutocomplete = new window.google.maps.places.Autocomplete(
                    startInputRef.current
                );
                startAutocomplete.addListener('place_changed', () => {
                    const place = startAutocomplete.getPlace();
                    setStartAddress(place.formatted_address || place.name);
                });
            }
        }
    }, [setDestinationAddress]);

    const handleSwapAddresses = () => {
        const temp = startAddress;
        setStartAddress(destinationAddress);
        setDestinationAddress(temp);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!startAddress || !destinationAddress) {
            setError('Please enter both start and destination addresses.');
            return;
        }
        try {
            let departureTimestamp;
            if (timeOption === 'leave_now') {
                departureTimestamp = Date.now();
            } else {
                departureTimestamp = new Date(departureTime).getTime();
            }

            const result = await calculateRouteWithPrayerStops(
                startAddress,
                destinationAddress,
                departureTimestamp,
                masjids
            );

            setDirections(result.directions);
            setRouteSteps(result.steps);
            setShowDirections(true);
        } catch (error) {
            setError('Error calculating route. Please try again.');
        }
    };



    return (
        <div className="p-4 ">
            <form onSubmit={handleSubmit} className="space-y-4">
                {/* Start Address Input */}
                <div>
                    <label htmlFor="startAddress" className="block mb-1 text-sm font-medium text-gray-700">
                        Start Location
                    </label>
                    <div className="flex items-center">
                        <input
                            ref={startInputRef}
                            id="startAddress"
                            type="text"
                            placeholder="Enter start location"
                            value={startAddress}
                            onChange={(e) => setStartAddress(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded"
                        />
                        <button
                            type="button"
                            onClick={handleSwapAddresses}
                            className="ml-2 p-2 bg-gray-200 rounded hover:bg-gray-300"
                        >
                            {/* Swap Icon */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm3-4a1 1 0 00-1-1H3a1 1 0 000 2h4a1 1 0 001-1zm0 8a1 1 0 00-1-1H3a1 1 0 100 2h4a1 1 0 001-1zm8-4a1 1 0 01-1 1h-4a1 1 0 110-2h4a1 1 0 011 1z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>

                {/* Destination Address Input */}
                <div>
                    <label htmlFor="destinationAddress" className="block mb-1 text-sm font-medium text-gray-700">
                        Destination
                    </label>
                    <input
                        ref={destinationInputRef}
                        id="destinationAddress"
                        type="text"
                        placeholder="Search destination"
                        value={destinationAddress}
                        onChange={(e) => setDestinationAddress(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded text-sm"
                    />
                </div>

                {/* Time Option Dropdown */}
                <div>
                    <label htmlFor="timeOption" className="block mb-1 text-sm font-medium text-gray-700">
                        Choose time option
                    </label>
                    <select
                        id="timeOption"
                        value={timeOption}
                        onChange={(e) => setTimeOption(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded text-sm"
                    >
                        <option value="leave_now">Leave Now</option>
                        <option value="depart_at">Depart At</option>
                    </select>
                </div>

                {/* Departure Time Input */}
                {timeOption !== 'leave_now' && (
                    <div>
                        <label htmlFor="departureTime" className="block mb-1 text-sm font-medium text-gray-700">
                            {timeOption === 'depart_at' ? 'Depart At' : 'Arrive By'}
                        </label>
                        <input
                            id="departureTime"
                            type="datetime-local"
                            value={departureTime}
                            onChange={(e) => setDepartureTime(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded text-sm"
                        />
                    </div>
                )}

                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 text-sm"
                >
                    Get Directions
                </button>
            </form>

            {showDirections && routeSteps.length > 0 && (
                <div className="mt-4">
                    <DirectionsList
                        routeSteps={routeSteps}
                        timeOption={timeOption}
                        showDirections={showDirections}
                        startAddress={startAddress}
                        destinationAddress={destinationAddress}
                    />
                </div>
            )}
        </div>
    );
}

export default RouteForm;
