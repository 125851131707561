// components/Header.js
import React from 'react';

function Header() {
    return (
        <header className="bg-green-600 text-white p-4 flex items-center">
            <img src="/logo512.png" alt="Kibbla Logo" className="h-8 mr-4" />
            {/*<h1 className="text-2xl font-bold">Kibbla Masjid Trip Planner</h1>*/}
        </header>
    );
}

export default Header;
