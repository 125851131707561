// utils/dataLoader.js
import Papa from 'papaparse';

export const loadMasjidData = (setMasjids, setError) => {
    fetch('/masjids.csv')
        .then((response) => response.text())
        .then((csvData) => {
            Papa.parse(csvData, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const masjidData = results.data
                                              .map((row) => {
                                                  const name = row['Account_Name'];
                                                  const latitude = parseFloat(row['latitude']);
                                                  const longitude = parseFloat(row['longitude']);
                                                  const address = [
                                                      row['Billing_Street'],
                                                      row['Billing_City'],
                                                      row['Billing_State'],
                                                      row['Billing_Zip'],
                                                      row['Billing_Country'],
                                                  ]
                                                      .filter(Boolean)
                                                      .join(', ');

                                                  if (name && !isNaN(latitude) && !isNaN(longitude)) {
                                                      return {
                                                          name,
                                                          latitude,
                                                          longitude,
                                                          address,
                                                      };
                                                  }
                                                  return null;
                                              })
                                              .filter(Boolean);

                    setMasjids(masjidData);
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    setError('Failed to parse masjid data. Please refresh the page.');
                },
            });
        })
        .catch((err) => {
            console.error('Failed to load masjid data:', err);
            setError('Failed to load masjid data. Please refresh the page.');
        });
};
