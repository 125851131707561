// utils/mapRenderer.js
export const renderDirections = (
    map,
    directionsResult,
    routeMarkersRef,
    directionsRendererRef
) => {
    clearMarkers(routeMarkersRef);

    if (directionsRendererRef.current) {
        directionsRendererRef.current.setMap(null);
    }

    const directionsRenderer = new window.google.maps.DirectionsRenderer({
        map: map,
        directions: directionsResult,
        suppressMarkers: true,
    });
    directionsRendererRef.current = directionsRenderer;

    const route = directionsResult.routes[0];

    // Add markers for start and end points
    addRouteMarker(
        map,
        route.legs[0].start_location,
        'Start',
        'green',
        routeMarkersRef
    );

    // Add markers for waypoints (prayer stops)
    for (let i = 0; i < route.legs.length - 1; i++) {
        const leg = route.legs[i];
        addRouteMarker(
            map,
            leg.end_location,
            `Prayer Stop ${i + 1}`,
            'yellow',
            routeMarkersRef
        );
    }

    // Add marker for end point
    addRouteMarker(
        map,
        route.legs[route.legs.length - 1].end_location,
        'End',
        'red',
        routeMarkersRef
    );
};

export const displayMasjidMarkers = (map, masjids, masjidMarkersRef) => {
    clearMarkers(masjidMarkersRef);

    masjids.forEach((masjid) => {
        const marker = new window.google.maps.Marker({
            map,
            position: { lat: masjid.latitude, lng: masjid.longitude },
            title: masjid.name,
            icon: {
                url: '/mosque-icon.png',
                scaledSize: new window.google.maps.Size(32, 32),
            },
        });

        const infoWindowContent = `
      <div>
        <h3>${masjid.name}</h3>
        <p>${masjid.address}</p>
      </div>
    `;

        const infoWindow = new window.google.maps.InfoWindow({
            content: infoWindowContent,
        });

        marker.addListener('click', () => {
            infoWindow.open(map, marker);
        });

        masjidMarkersRef.current.push(marker);
    });
};

export const clearMarkers = (markersRef) => {
    if (markersRef.current) {
        markersRef.current.forEach((marker) => marker.setMap(null));
        markersRef.current = [];
    }
};

const addRouteMarker = (map, position, title, color, markersRef) => {
    const marker = new window.google.maps.Marker({
        map,
        position,
        title,
        icon: {
            url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
            scaledSize: new window.google.maps.Size(32, 32),
        },
    });
    markersRef.current.push(marker);
};
