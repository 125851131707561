// utils/routeCalculator.js
import { CalculationMethod, Coordinates, PrayerTimes, Madhab } from 'adhan';
import moment from 'moment-timezone';
import tzlookup from 'tz-lookup'; // Import tz-lookup for timezone determination

export const calculateRouteWithPrayerStops = async (
    startAddress,
    endAddress,
    departureTimestamp,
    masjids
) => {
    const directionsService = new window.google.maps.DirectionsService();

    const initialRequest = {
        origin: startAddress,
        destination: endAddress,
        travelMode: window.google.maps.TravelMode.DRIVING,
        drivingOptions: {
            departureTime: new Date(departureTimestamp),
        },
    };

    try {
        const initialResult = await new Promise((resolve, reject) => {
            directionsService.route(initialRequest, (result, status) => {
                if (status === 'OK') {
                    resolve(result);
                } else {
                    reject(new Error(status));
                }
            });
        });

        const route = initialResult.routes[0];
        const totalDuration = route.legs[0].duration.value * 1000; // Convert to milliseconds
        const arrivalTimestamp = departureTimestamp + totalDuration;

        const waypoints = await findPrayerWaypoints(
            route,
            departureTimestamp,
            arrivalTimestamp,
            masjids
        );

        if (waypoints.length > 0) {
            const waypointRequest = {
                ...initialRequest,
                waypoints: waypoints.map((wp) => ({
                    location: wp.location,
                    stopover: true,
                })),
                optimizeWaypoints: false,
            };

            const waypointResult = await new Promise((resolve, reject) => {
                directionsService.route(waypointRequest, (result, status) => {
                    if (status === 'OK') {
                        resolve(result);
                    } else {
                        reject(new Error(status));
                    }
                });
            });

            const steps = extractSteps(
                waypointResult.routes[0],
                departureTimestamp,
                waypoints
            );
            return { directions: waypointResult, steps };
        } else {
            const steps = extractSteps(route, departureTimestamp, []);
            return { directions: initialResult, steps };
        }
    } catch (error) {
        console.error('Route calculation error:', error);
        throw error;
    }
};

const findPrayerWaypoints = async (
    route,
    departureTimestamp,
    arrivalTimestamp,
    masjids
) => {
    const waypoints = [];
    const leg = route.legs[0];
    let currentTimeUTC = moment.utc(departureTimestamp);
    let lastPrayerTimeUTC = moment.utc(departureTimestamp).subtract(1, 'hour');

    for (let i = 0; i < leg.steps.length; i++) {
        const step = leg.steps[i];
        const stepDuration = moment.duration(step.duration.value, 'seconds');
        const stepEndTimeUTC = moment.utc(currentTimeUTC).add(stepDuration);

        const lat = step.end_location.lat();
        const lng = step.end_location.lng();
        const timezoneName = tzlookup(lat, lng); // Use tz-lookup to get timezone

        // Convert currentTimeUTC to local time for prayer time calculation
        const currentTimeLocal = currentTimeUTC.clone().tz(timezoneName);

        // Calculate prayer times using local date and time
        const prayerTimes = calculatePrayerTimes(
            lat,
            lng,
            currentTimeLocal.toDate()
        );

        // Find next prayer after lastPrayerTime and currentTime
        let nextPrayer = findNextPrayer(prayerTimes, currentTimeLocal, lastPrayerTimeUTC.clone().tz(timezoneName));

        if (nextPrayer) {
            // Convert nextPrayer.time back to UTC for comparison
            const nextPrayerTimeUTC = nextPrayer.time.clone().utc();

            if (nextPrayerTimeUTC.isBetween(currentTimeUTC, stepEndTimeUTC)) {
                const nearestLocation = findNearestPrayerLocation(step.end_location, masjids);
                const prayerStopTimeUTC = nextPrayerTimeUTC;

                if (nearestLocation && prayerStopTimeUTC.diff(currentTimeUTC, 'minutes') <= 10) {
                    // Masjid found within 10 minutes of prayer time
                    waypoints.push(createWaypoint(nearestLocation, nextPrayer, prayerStopTimeUTC));
                } else {
                    // No nearby masjid, find a suitable POI
                    const poi = await findNearestPOI(step.end_location);
                    waypoints.push(createWaypoint(poi, nextPrayer, prayerStopTimeUTC));
                }

                lastPrayerTimeUTC = prayerStopTimeUTC;
            }
        }

        currentTimeUTC = stepEndTimeUTC;
        if (currentTimeUTC.isAfter(moment.utc(arrivalTimestamp))) break;
    }

    return waypoints;
};

const calculatePrayerTimes = (lat, lng, date) => {
    const coordinates = new Coordinates(lat, lng);
    const timezoneName = tzlookup(lat, lng);
    const params = CalculationMethod.NorthAmerica();
    params.madhab = Madhab.Hanafi;

    // Ensure the date is in the local time zone
    const localDate = moment(date).tz(timezoneName).toDate();

    const prayerTimes = new PrayerTimes(coordinates, localDate, params);

    return {
        fajr: moment(prayerTimes.fajr).tz(timezoneName),
        sunrise: moment(prayerTimes.sunrise).tz(timezoneName),
        dhuhr: moment(prayerTimes.dhuhr).tz(timezoneName),
        asr: moment(prayerTimes.asr).tz(timezoneName),
        maghrib: moment(prayerTimes.maghrib).tz(timezoneName),
        isha: moment(prayerTimes.isha).tz(timezoneName)
    };
};

const findNextPrayer = (prayerTimes, currentTimeLocal, lastPrayerTimeLocal) => {
    const prayers = ['fajr', 'dhuhr', 'asr', 'maghrib', 'isha'];
    for (let prayer of prayers) {
        if (
            prayerTimes[prayer].isAfter(lastPrayerTimeLocal) &&
            prayerTimes[prayer].isAfter(currentTimeLocal)
        ) {
            return { name: prayer, time: prayerTimes[prayer] };
        }
    }
    return null;
};

const findNearestPrayerLocation = (location, masjids) => {
    let nearestPrayerLocation = null;
    let minDistance = Infinity;

    masjids.forEach((masjid) => {
        const distance = calculateDistance(
            location.lat(),
            location.lng(),
            masjid.latitude,
            masjid.longitude
        );
        if (distance < minDistance) {
            minDistance = distance;
            nearestPrayerLocation = masjid;
        }
    });

    return nearestPrayerLocation;
};

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
};

const deg2rad = (deg) => {
    return (deg * Math.PI) / 180;
};

const findNearestPOI = async (location) => {
    // Implement logic to find nearest POI (gas station, restaurant, etc.)
    // This might involve using Google Places API or similar service
    // For now, we'll return a dummy POI
    return {
        name: "Nearby POI",
        latitude: location.lat(),
        longitude: location.lng(),
        address: "Nearby POI Address"
    };
};

const createWaypoint = (location, prayer, timeUTC) => {
    const timezoneName = tzlookup(location.latitude, location.longitude);
    const timeLocal = timeUTC.clone().tz(timezoneName);
    const timezoneAbbr = timeLocal.format('z');

    return {
        location: new window.google.maps.LatLng(location.latitude, location.longitude),
        prayerName: prayer.name,
        prayerTime: timeLocal.format(`ddd, MMM D hh:mm A [${timezoneAbbr}]`),
        prayerAddress: location.address,
        name: location.name, // Add this line if you need the name
        timezone: timezoneName,
        timezoneAbbr: timezoneAbbr,
        city: parseCityFromAddress(location.address)
    };
};


const extractSteps = (route, departureTimestamp, waypoints) => {
    const steps = [];
    let currentTimeUTC = moment.utc(departureTimestamp);
    let waypointIndex = 0;

    route.legs.forEach((leg, legIndex) => {
        leg.steps.forEach((step) => {
            const stepDuration = moment.duration(step.duration.value, 'seconds');
            currentTimeUTC = currentTimeUTC.add(stepDuration);

            const lat = step.end_location.lat();
            const lng = step.end_location.lng();
            const timezoneName = tzlookup(lat, lng);
            const arrivalTimeLocal = currentTimeUTC.clone().tz(timezoneName);
            const timezoneAbbr = arrivalTimeLocal.format('z');

            steps.push({
                instructions: step.instructions,
                distance: step.distance.text,
                duration: step.duration.text,
                arrivalTime: arrivalTimeLocal.format(`ddd, MMM D hh:mm A [${timezoneAbbr}]`),
                isWaypoint: false,
            });
        });

        // After each leg, check for a waypoint
        if (waypointIndex < waypoints.length) {
            currentTimeUTC = currentTimeUTC.add(20 * 60, 'seconds'); // Add 20 minutes for the stop
            const { timezone, timezoneAbbr } = waypoints[waypointIndex];
            const arrivalTimeLocal = currentTimeUTC.clone().tz(timezone);

            steps.push({
                instructions: `Prayer stop at ${waypoints[waypointIndex].prayerName} for ${waypoints[waypointIndex].prayerName}`,
                distance: '',
                duration: '20 mins',
                arrivalTime: arrivalTimeLocal.format(`ddd, MMM D hh:mm A [${timezoneAbbr}]`),
                isWaypoint: true,
                prayerName: waypoints[waypointIndex].prayerName,
                prayerAddress: waypoints[waypointIndex].prayerAddress,
                timezone: timezone,
                timezoneAbbr: timezoneAbbr,
                city: waypoints[waypointIndex].city,
            });
            waypointIndex++;
        }
    });

    // console.log('Extracted steps:', steps);
    return steps;
};

const parseCityFromAddress = (address) => {
    const parts = address.split(',');
    if (parts.length >= 2) {
        return parts[parts.length - 2].trim();
    }
    return 'Unknown City';
};
