import React, {useState} from 'react';
import DOMPurify from 'dompurify';
import moment from 'moment'; // Added moment import

function DirectionsList({ routeSteps, timeOption, showDirections, startAddress, destinationAddress }) {
    const [expandedStep, setExpandedStep] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    if (routeSteps.length === 0) return null;

    const handleOpenInNav = (service) => {
        const waypoints = routeSteps
            .filter(step => step.isWaypoint)
            .map(step => step.prayerAddress ? encodeURIComponent(step.prayerAddress) : '')

        let url;

        if (service === 'google') {
            const waypointsString = waypoints.length > 0 ? `&waypoints=${waypoints.join('|')}` : '';
            url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(startAddress)}&destination=${encodeURIComponent(destinationAddress)}${waypointsString}&travelmode=driving`;
        } else {
            url = `http://maps.apple.com/?saddr=${encodeURIComponent(startAddress)}&daddr=${encodeURIComponent(destinationAddress)}&dirflg=d`;
        }

        window.open(url, '_blank');
        setIsOpen(false);
    };


    // Calculate total duration and distance
    const totalDuration = routeSteps.reduce((total, step) => {
        if (!step.duration) return total;
        const durationParts = step.duration.split(' ');
        let duration = 0;
        for (let i = 0; i < durationParts.length; i += 2) {
            const value = parseInt(durationParts[i]);
            const unit = durationParts[i + 1];
            if (unit && unit.includes('hour')) {
                duration += value * 60;
            } else if (unit && unit.includes('min')) {
                duration += value;
            }
        }
        return total + duration;
    }, 0);

    const totalDistance = routeSteps.reduce((total, step) => {
        if (!step.distance) return total;
        const distanceParts = step.distance.split(' ');
        if (distanceParts.length === 2) {
            let distance = parseFloat(distanceParts[0]);
            const unit = distanceParts[1];
            if (unit && unit.includes('ft')) {
                distance /= 5280; // Convert feet to miles
            }
            return total + distance;
        }
        return total;
    }, 0);

    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;

    return (
        <div className="max-w-md mx-auto bg-white shadow-lg">
            <div className="p-4 border-b">
                <div className="flex items-center mb-2">
                    <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 011 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span className="text-sm text-gray-600">{startAddress}</span>
                </div>
                <div className="flex items-center">
                    <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                    <span className="text-sm text-gray-600">{destinationAddress}</span>
                </div>
            </div>
            <div className="p-4 border-b">
                <div className="text-2xl font-bold">{hours} hr {minutes} min ({totalDistance.toFixed(0)} miles)</div>
                <div className="text-sm text-gray-600 mt-1">via I-80 E and I-76 E</div>
                <div className="text-sm text-gray-600">Fastest route, the usual traffic</div>
                {timeOption !== 'leave_now' && (
                    <div className="text-sm text-orange-600 mt-2">
                        ⚠️ This route has tolls.
                    </div>
                )}
                <div className="text-sm text-orange-600">
                    ⚠️ Your destination is in a different time zone.
                </div>
            </div>
            <div className="divide-y">
                {routeSteps.map((step, index) => (
                    <div key={index} className="p-4">
                        <div
                            className="flex items-start cursor-pointer"
                            onClick={() => setExpandedStep(expandedStep === index ? null : index)}
                        >
                            <div className="mr-4 mt-1">
                                {getDirectionIcon(step)}
                            </div>
                            <div>
                                <div className="font-semibold capitalize">
                                    {step.isWaypoint ? `Prayer Stop: ${step.prayerName || 'Prayer'} at ${step.masjidName || 'Masjid'}` : <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(step.instructions || '')}}/>}
                                </div>
                                <div className="text-sm text-gray-600">
                                    {step.arrivalTime && <span>at {step.arrivalTime}</span>}
                                </div>
                                <div className="text-sm text-gray-600">
                                    {step.distance && <span>{step.distance}</span>}  {step.duration && <span> ({step.duration})</span>}
                                </div>
                            </div>
                        </div>
                        {expandedStep === index && (
                            <div className="mt-2 ml-10 text-sm text-gray-600">
                                {step.isWaypoint ? (
                                    <>
                                        <div>{step.prayerAddress || 'Address not available'}</div>
                                        <div>Stop for: {step.duration || 'Unknown duration'}</div>
                                        {/* <div>Leave at: {step.arrivalTime ? addTwentyMinutes(step.arrivalTime).format('hh:mm A') : 'Unknown time'}</div> */}
                                    </>
                                ) : (
                                    step.subSteps && step.subSteps.map((subStep, subIndex) => (
                                        <div key={subIndex} className="mt-1">
                                            {subStep.instruction}
                                            {subStep.distance && <span className="ml-2">{subStep.distance}</span>}
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

function getDirectionIcon(step) {
    if (step.isWaypoint) {
        return (
            <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
            </svg>
        );
    }
    // You can add more icons for different direction types here
    return (
        <svg className="w-6 h-6 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
        </svg>
    );
}

function addTwentyMinutes(arrivalTime) {
    return moment(arrivalTime).add(20, 'minutes');
}

export default DirectionsList;