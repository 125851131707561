// App.js
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import RouteForm from './components/RouteForm';
import MapComponent from './components/MapComponent';
import { loadMasjidData } from './utils/dataLoader';

function App() {
    const [masjids, setMasjids] = useState([]);
    const [directions, setDirections] = useState(null);
    const [routeSteps, setRouteSteps] = useState([]);
    const [error, setError] = useState(null);
    const [showMasjids, setShowMasjids] = useState(false);
    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
    const [zoomToDestination, setZoomToDestination] = useState(false);
    const [destinationAddress, setDestinationAddress] = useState('');
    const [showDirections, setShowDirections] = useState(false);

    useEffect(() => {
        loadMasjidData(setMasjids, setError);

        const loadGoogleMaps = () => {
            if (!window.google || !window.google.maps) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
                script.async = true;
                script.defer = true; // Add defer attribute for better loading
                script.addEventListener('load', () => {
                    console.log('Google Maps API loaded');
                    setIsGoogleMapsLoaded(true);
                });
                script.addEventListener('error', () => {
                    console.error('Error loading Google Maps API');
                    setError('Failed to load Google Maps. Please check your internet connection and refresh the page.');
                });
                document.body.appendChild(script);
            } else {
                setIsGoogleMapsLoaded(true);
            }
        };


        loadGoogleMaps();
    }, []);

    const toggleMasjids = () => {
        setShowMasjids((prev) => !prev);
    };

    return (
        <div className="flex flex-col h-screen">
            <Header />

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative m-4">
                    {error}
                </div>
            )}

            <div className="flex flex-1 overflow-hidden">
                {/* Side Navigation (RouteForm and DirectionsList) */}
                <div className="w-1/3 h-full flex flex-col bg-white shadow-lg overflow-y-auto">
                    {isGoogleMapsLoaded && (
                        <RouteForm
                            setDirections={setDirections}
                            setRouteSteps={setRouteSteps}
                            setError={setError}
                            masjids={masjids}
                            setZoomToDestination={setZoomToDestination}
                            destinationAddress={destinationAddress}
                            setDestinationAddress={setDestinationAddress}
                            setShowDirections={setShowDirections}
                            showDirections={showDirections}
                            routeSteps={routeSteps}
                        />
                    )}
                </div>

                {/* Map Area */}
                <div className="w-4/5 relative">
                    {isGoogleMapsLoaded ? (
                        <MapComponent
                            directions={directions}
                            masjids={masjids}
                            showMasjids={showMasjids}
                            zoomToDestination={zoomToDestination}
                            destinationAddress={destinationAddress}
                        />
                    ) : (
                        <div className="w-full h-full flex items-center justify-center">
                            Loading Google Maps...
                        </div>
                    )}

                    <button
                        onClick={toggleMasjids}
                        className="absolute bottom-40 right-2 bg-white p-2 rounded-full shadow-md flex items-center justify-center"
                    >
                        {showMasjids ? (
                            <img src="/mosque-icon.png" alt="Hide Masjids" className="w-6 h-6" />
                        ) : (
                            <img src="/mosque-icon-noselection.png" alt="Show Masjids" className="w-6 h-6" />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default App;
