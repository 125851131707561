import React, { useEffect, useRef, useCallback } from 'react';
import {
  renderDirections,
  displayMasjidMarkers,
  clearMarkers,
} from '../utils/mapRenderer';

function MapComponent({
  directions,
  masjids,
  showMasjids,
  zoomToDestination,
  destinationAddress,
}) {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const routeMarkersRef = useRef([]);
  const masjidMarkersRef = useRef([]);
  const directionsRendererRef = useRef(null);

  const initMap = useCallback(() => {
    if (!mapInstanceRef.current && window.google && window.google.maps) {
      const mapOptions = {
        zoom: 10,
        center: { lat: 37.0902, lng: -95.7129 },
        mapId: process.env.REACT_APP_MAP_ID,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.DEFAULT,
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_CENTER,
        },
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
        streetViewControl: true,
        streetViewControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_TOP,
        },
      };

      mapInstanceRef.current = new window.google.maps.Map(
        mapRef.current,
        mapOptions
      );

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            mapInstanceRef.current.setCenter(userLocation);
            mapInstanceRef.current.setZoom(12);
          },
          () => console.error('Error: The Geolocation service failed.')
        );
      }
    }
  }, []);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useEffect(() => {
    if (mapInstanceRef.current && directions) {
      renderDirections(
        mapInstanceRef.current,
        directions,
        routeMarkersRef,
        directionsRendererRef
      );
    }
  }, [directions]);

  useEffect(() => {
    if (mapInstanceRef.current) {
      if (showMasjids && masjids.length > 0) {
        displayMasjidMarkers(
          mapInstanceRef.current,
          masjids,
          masjidMarkersRef
        );
      } else {
        clearMarkers(masjidMarkersRef);
      }
    }
  }, [masjids, showMasjids]);

  const geocodeAddress = useCallback((address) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results[0]) {
          resolve(results[0].geometry.location);
        } else {
          reject(new Error(`Geocode was not successful: ${status}`));
        }
      });
    });
  }, []);

  useEffect(() => {
    if (zoomToDestination && destinationAddress && mapInstanceRef.current) {
      geocodeAddress(destinationAddress)
        .then((location) => {
          mapInstanceRef.current.setCenter(location);
          mapInstanceRef.current.setZoom(15);
        })
        .catch((error) => console.error(error.message));
    }
  }, [zoomToDestination, destinationAddress, geocodeAddress]);

  return <div ref={mapRef} style={{ width: '100%', height: '100%' }} />;
}

export default React.memo(MapComponent);